import Hero from './hero';
import Presentation from './presentation';
import './styles/index.scss';

function App() {
  return (
    <main id="main-root-malin-hair-n-makeup">
      {/* <Menu /> */}
      <Hero />
      {/* <Portfolio /> */}
      <Presentation />
      {/* <Wedding />
        <Contact />
        <Footer /> */}
    </main>
  );
}

export default App;
