import { links } from '../general/links';
import imgsrc from '../images/malin.jpg';
import Arrow from '../assets/svg/Arrow';

const Presentation = () => {
  return (
    <div className="presentationWrapper">
      <a id="presentation" />
      <div className="presentationImgWrapper">
        <div className="picGradient" />
        <img src={imgsrc} alt="" />
      </div>
      <div className="presentationTextWrapper">
        <h2>Välkommen till Hair N Makeup</h2>
        <p>Jag heter Malin Andersson och är frisör, makeupartist och maskör.</p>
        <p>
          {
            'Har många års erfarenhet inom film, salong och frilansuppdrag. Sedan 2018 jobbar jag heltid som frisör på Infinum, salongen på Skönhetsfabriken.\nDär erbjuder tjänster inom klipp, färg och makeup.'
          }
        </p>
        <p>
          Har jobbat med de flesta skådespelare i svensk film och många är
          fortfarande kunder på salongen, även om jag inte gör lika många
          filmprojekt längre utan riktar mig mer mot privatkunder och
          individuell service nu för tiden.
        </p>
        <p>
          <i>Min styrka är helhet.</i> Jag är engagerad i att förstå dig som
          kund och utifrån dina önskemål, ge dig din bästa upplevelse och
          resultat.
        </p>
        <p>
          Vill du veta vad mina kunder tycker om mig?{'\n'}
          <a
            className="review-link"
            href={links.review}
            target="_blank"
            rel="noreferrer"
          >
            <Arrow />
            Läs mina omdömen
          </a>
        </p>
        <p>
          Om du vill boka tid eller komma i kontakt med mig kan du
          <a
            className="review-link"
            href={links.booking}
            target="_blank"
            rel="noreferrer"
          >
            <Arrow />
            Boka en tid via Boka direkt
          </a>
          <a className="review-link" href={`mailto:${links.email}`}>
            <Arrow />
            Mejla {links.email}
          </a>
          <a className="review-link" href={`tel:${links.phone}`}>
            <Arrow />
            Ringa {links.phonePretty}
          </a>
        </p>
      </div>
    </div>
  );
};

export default Presentation;
