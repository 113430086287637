import { links } from '../general/links';
import imgsrc from '../images/hero-pic.jpg';

const Hero = () => (
  <div className="heroWrapper">
    <div className="picGradient" />
    <img src={imgsrc} alt="" />
    <div className={`greeting`}>
      <h1>Malin Andersson</h1>
      <p>Frisör - Makeup artist - Maskör</p>
      <a target="_blank" href={links.booking} rel="noreferrer">
        Boka tid här
      </a>
    </div>
  </div>
);

export default Hero;
