const links = {
  review: 'https://www.bokadirekt.se/places/infinum-40268#staff',
  booking:
    'https://www.bokadirekt.se/places/infinum-40268?sr=BAF5751A_65DA_4372_BB94_46BAF4940B61',
  email: 'maskmalin@gmail.com',
  phone: '0760948472',
  phonePretty: '0760 - 94 84 72',
};

const menulinks = [
  {
    href: '#gallery',
    name: 'Portfolio',
  },
  {
    href: '#presentation',
    name: 'Om mig',
  },
  {
    href: '#wedding',
    name: 'Mer än bara hår',
  },
  {
    href: '#contact',
    name: 'Kontakt & priser',
  },
];
export { links, menulinks };
